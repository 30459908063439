<template>
  <v-layout width="100%">
    <v-app-bar app height="124" class="align-start elevation-1">
      <v-col cols="12" class="py-0">
        <v-row dense align="center">
          <v-col cols="auto" class="d-flex d-lg-none pa-0">
            <!--Platzhalter für mobile Menu-->
            <div style="width: 25px"></div>
          </v-col>
          <v-col cols="auto">
            <v-btn icon x-large @click="$router.go(-1)">
              <v-icon x-large>mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-toolbar-title
              >{{ dataset.Name }}
              <span v-if="dataset.Abkürzung">
                {{ " (" + dataset.Abkürzung + ")" }}
              </span></v-toolbar-title
            >
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto" v-if="checkCurrentUser">
            <v-btn color="orange lighten-2" :to="linkEditDataset">
              <v-icon class="grey--text text--lighten-4">mdi-pencil</v-icon>
              <span class="ml-1 text--capitalize grey--text text--lighten-4 d-none d-lg-flex">Bearbeiten</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-app-bar>

    <v-container fluid class="px-0">
      <v-col cols="11" xl="8" lg="9" md="11" class="mx-auto px-0">
        <!--Hauptdaten-->
        <v-row dense class="align-center justify-center pb-5">
          <v-card tile outlined class="flex-grow-1">
            <v-col cols="12">
              <v-card-title class="px-2">
                <v-row dense>
                  <v-col cols="12">
                    <v-toolbar-title> {{ dataset.Name }} {{ "(" + dataset.Abkürzung + ")" }} </v-toolbar-title>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider inset></v-divider>
              <v-card-text class="px-2 subtitle-2">
                <v-row dense align="start">
                  <v-col cols="12">
                    <v-row dense align="start">
                      <v-col cols="12" xl="6" lg="6" md="6">
                        <v-row dense>
                          <v-col cols="5" md="6" sm="6" class="font-weight-bold">
                            Name
                          </v-col>
                          <v-col cols="6">
                            <span v-if="dataset.Name">{{ dataset.Name }}</span>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" xl="6" lg="6" md="6">
                        <v-row dense>
                          <v-col cols="5" md="6" sm="6" class="font-weight-bold">
                            Abkürzung
                          </v-col>
                          <v-col cols="6">
                            <span v-if="dataset.Abkürzung">{{ dataset.Abkürzung }}</span>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                        Kundennummer
                      </v-col>
                      <v-col cols="6" class="">
                        <span v-if="dataset.Kundennummer">
                          {{ dataset.Kundennummer }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                        Ansprechpartner
                      </v-col>
                      <v-col cols="6">
                        <span v-if="dataset.Kontaktperson">
                          {{ dataset.Kontaktperson }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                        Sortiment
                      </v-col>
                      <v-col cols="6">
                        <span v-if="dataset.Sortiment">
                          {{ dataset.Sortiment }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-divider inset class="my-3"></v-divider>
                <v-row dense>
                  <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                    Telefon
                  </v-col>
                  <v-col cols="6">
                    <span v-if="dataset.Telefon">
                      {{ dataset.Telefon }}
                    </span>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                    Email
                  </v-col>
                  <v-col cols="6">
                    <span v-if="dataset.Email">
                      {{ dataset.Email }}
                    </span>
                  </v-col>
                </v-row>

                <v-divider inset class="my-3"></v-divider>

                <v-row dense align="center">
                  <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                    Website
                  </v-col>
                  <v-col cols="5" v-if="dataset.Website">
                    {{ dataset.Website }}
                  </v-col>
                  <v-col cols="1" v-if="dataset.Website">
                    <v-btn icon outlined :href="linkVisitWebsite" target="_blank"><v-icon>mdi-web</v-icon></v-btn>
                  </v-col>
                </v-row>
                <v-row dense align="center">
                  <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                    Shop
                  </v-col>
                  <v-col cols="5" v-if="dataset.Shop">
                    {{ dataset.Shop }}
                  </v-col>
                  <v-col cols="1" v-if="dataset.Shop">
                    <v-btn icon outlined :href="linkVisitShop" target="_blank"><v-icon>mdi-basket-outline</v-icon></v-btn>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                    IBAN
                  </v-col>
                  <v-col cols="6">
                    <span v-if="dataset.IBAN">
                      {{ dataset.IBAN }}
                    </span>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                    BIC
                  </v-col>
                  <v-col cols="6">
                    <span v-if="dataset.BIC">
                      {{ dataset.BIC }}
                    </span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-card>
        </v-row>
        <!--Artikeleigenschaften und Beschreibung-->
        <v-row dense class="align-center justify-center">
          <v-card tile outlined class="flex-grow-1">
            <v-col cols="12">
              <v-card-title class="px-2">
                <v-row dense>
                  <v-col cols="12">
                    <v-toolbar-title>
                      Anschrift
                    </v-toolbar-title>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider inset></v-divider>
              <v-card-text class="px-2 subtitle-2">
                <v-row dense align="start">
                  <v-col cols="12">
                    <v-row dense>
                      <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                        Land
                      </v-col>
                      <v-col cols="7" xl="9" lg="9" md="9" sm="6">
                        <span v-if="dataset.Land">
                          {{ dataset.Land }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row dense align="center">
                      <v-col cols="5" xl="3" lg="3" md="3" sm="6" class="font-weight-bold">
                        Anschrift
                      </v-col>
                      <v-col cols="5">
                        <v-row dense>
                          <v-col class="py-0">
                            <span v-if="dataset.Straße">{{ dataset.Straße }}</span>
                            <span v-if="dataset.Hausnummer">{{ " " + dataset.Hausnummer + "," }}</span>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col class="py-0">
                            <span v-if="dataset.PLZ">{{ dataset.PLZ }}</span>
                            <span v-if="dataset.Ort">{{ " " + dataset.Ort }}</span>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="1" v-if="dataset.Straße && dataset.Hausnummer && dataset.PLZ && dataset.Ort">
                        <v-btn icon outlined :href="linkViewGoogleMaps" target="_blank"><v-icon>mdi-google-maps</v-icon></v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-card>
        </v-row>
      </v-col>
    </v-container>
  </v-layout>
</template>

<script>
export default {
  created() {
    this.initialize();
  },

  mounted() {
    this.initialize();
  },

  data: () => ({
    dataset: {},
  }),

  computed: {
    checkCurrentUser() {
      const user = localStorage.getItem("user");

      if (user === "Aline" || user === "Admin") {
        return true;
      } else return false;
    },

    linkEditDataset() {
      if (this.$route.params.id > 0) {
        return `/wws/database/${this.$route.meta.request}/dataset/edit/${this.$route.params.id}`;
      }
    },

    linkViewGoogleMaps() {
      if (this.dataset.Straße && this.dataset.Hausnummer && this.dataset.PLZ && this.dataset.Ort) {
        return `https://www.google.de/maps/place/${this.dataset.Straße}+${this.dataset.Hausnummer},+${this.dataset.PLZ}+${this.dataset.Ort}/`;
      }
    },

    linkVisitWebsite() {
      if (this.dataset.Website) {
        return `https://${this.dataset.Website}/`;
      }
    },

    linkVisitShop() {
      if (this.dataset.Shop) {
        return `https://${this.dataset.Shop}/`;
      }
    },
  },

  methods: {
    async initialize() {
      const response = await fetch(`/api/database/${this.$route.meta.request}/dataset/show/${this.$route.params.id}`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }
    },
  },
};
</script>
